const mixins = {
    methods: {
        openModal(componentName, props = null) {
            let modalTitle = ""
            let modalClass = ""
            // Existing class : small, medium, big, invisible
            switch (componentName) {
                case "edit-time-range":
                case "copie-day-time-range":
                case "alert":
                case "validation":
                case "providers-status":
                case "double-auth":
                    modalTitle = props.title
                    modalClass = "small"
                    break
                case "rgpd":
                    modalTitle = props.title
                    modalClass = "medium"
                    break
            }
            this.emitter.emit("modal", {
                type: componentName,
                title: modalTitle,
                customClass: modalClass,
                props,
            })
        },
        closeModal(event = null, fonction = null) {
            this.emitter.emit("close-modal", event)
            if (fonction) {
                fonction()
            }
        },
    },
}
export default mixins
